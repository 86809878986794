
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
  }

  /* .container {
    max-width: 800px;
  } */
 
  h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #e9ecef;
    padding-bottom: 10px;
    text-align: center;
  }
 
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #e9ecef;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
   
  input[type='text'],
  textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  input[type='password'],
  textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
    .button2 {
    background-color: #007bff;
    color: #ffffff;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
 
  .button2:hover {
    background-color: #0056b3;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  table th,
  table td {
    border: 1px solid #dee2e6;
    padding: 0.5rem;
  }
  
  table th {
    font-weight: bold;
    background-color: #e9ecef;
  }
  
  table img {
    max-width: 100px;
    height: auto;
  }
  
  .sign-out {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  

  
  .login-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-form {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    /* background-color: #ffffff; */
  }
  .error{
    color: #ffffff;
  }
  
   