body {
	margin: 0;
	font-family: Arial, sans-serif;
}

/* .nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5); 
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 1rem;
	box-sizing: border-box;
	z-index: 1000;
} */

.hamburger-menu {
	display: flex;
	flex-direction: column;
	padding-right: 10px;
	justify-content: space-between;
	width: 30px;
	height: 20px;
	cursor: pointer;
	z-index: 9;
}

.cancel_btn {
	position: absolute;
	top: 20px;
	right: 10px;
	z-index: 999999999;
	cursor: pointer;
	/* transition: 0.8s; */
}

.line {
	height: 3px;
	width: 100%;
	background-color: white;
}

.menu {
	position: fixed;
	top: 0;
	left: -100%;
	width: 400px;
	height: 100%;
	background-color: rgba(46, 46, 46, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease;
	z-index: 99999999;
}

.menu ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: left;
}

.menu li {
	margin-bottom: 1rem;
	border-bottom: 1px solid white;
	padding-bottom: 10px;
}

.menu li:nth-child(8) {
	border-bottom: 0px;
}

.menu a {
	text-decoration: none;
	color: white;
	font-size: 1.2rem;
	font-family: Akira, sans-serif;
}
.menu.show {
	left: 0;
}
.email {
	color: white;
	font-size: 0.5rem;
}

#user-title-wrapper #dropdown {
	top: 40px;
	background: #99c6ff;
	right: 0;
	border: 3px solid #1c5ba5;
	display: none;
	transform: translateY(10px);
	-webkit-transform: translateY(10px);
	-moz-transform: translateY(10px);
	-ms-transform: translateY(10px);
	-o-transform: translateY(10px);
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}

#user-title-wrapper:hover #dropdown {
	display: block;
	transform: translateY(0px);
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
}
