/* Styles for the AdminDashboard */

.App {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 90px;
    padding: 20px;
    background-color: #f8f8f8;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  label {
    font-size: 16px;
    font-weight: bold;
    /* color: #333; */
  }
  
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"],
  select {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  input[type="file"] {
    font-size: 14px;
    font-weight: normal;
    color: #333;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  
  br {
    margin: 10px 0;
  }
  
  