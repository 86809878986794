.admin-menu ul {
    list-style-type: none;
    display: flex;
}

.admin-menu ul li a {
    color: #fff;
    background: #0089ff;
    display: inline-block;
    padding: 5px 10px;
    margin: 0 10px;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.admin-menu ul li a:hover {
    border-bottom: 1px solid #fff;
}