#search-popup {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 1rem 0;
    z-index: 21;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

#search-popup.hide {
    opacity: 0;
}

#search-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: #2a2a2a;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    cursor: pointer;
}

#search-close svg {
    padding: 3px;
}

#search-popup input {
    max-width: 500px;
    margin: 0 10px;
    padding: 5px 3px;
    background: #2a2a2a;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

#search-popup #search-card {
    color: white;
    min-height: 100px;
    display: flex;
    background: #2a2a2a;
    margin: 1rem 0;
    border-radius: 10px;
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

#search-popup #search-card .img {
    width: 150px;
}

#search-popup #search-card .content {
    width: 100%;
    padding: 1rem;
}

#search-popup #search-card .content h4 {
    font-size: 18px;
}